<!--
 * @Author: your name
 * @Date: 2020-04-23 16:12:30
 * @LastEditTime: 2020-12-03 09:57:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/useHelp/index.vue
-->

<template>
  <div class="preview-news">
    <div class="main">
      <div class="left">
        <h3>长沙市这所小学打造智慧校园，极具未来感</h3>
        <div class="news-setting">
          <h3 class="time">
            发布时间： 2019-09-19
          </h3>
          <!-- <h5 class="num">
            浏览量： 28289
          </h5> -->
        </div>
        <div class="news-content" style="margin-top:20px">
          <p style="text-align:justify;">
            今年4月，教育部公布了2019年度“智慧教育示范区”建设项目名单，长沙市成功上榜。并随即加快了教育信息化建设进程。
          </p>
          <p style="text-align:justify;">
            近日，记者在长沙市实验小学发现了许多神奇之处：上下学经过校门口，如果有需要，孩子的照片可以实时发送到家长手机上；教室里，物联网设备自动调节光线的强弱、温湿度的变化，从而让孩子们的身体处于最舒适的状态；通过“聪明”的课桌、黑板和先进的云技术，充满活力和笑声的沉浸式课堂让人着迷……
          </p>
          <p style="text-align:justify;">
            长沙市实验小学老师：“智慧课堂”的引入对我的教学带来了非常大的改变，我只需要给孩子们抛出一个话题，就可以围绕这个话题进行自主的讨论和研究。我把我的板书传送给他们，他们就可以自如地进行数学的学习，让更多的孩子加入进来一起讨论。这样的数学课堂对孩子们来说兴趣更广泛了，枯燥的数学被智慧课堂改造得更好玩了。”
          </p>
          <p style="text-align:justify;">
            长沙市实验小学学生反映:“智慧课桌的抢答和投票功能很吸引大家。我用了这个智慧课桌以后，它就提高了我对数学的兴趣，让我更想学习数学。”
          </p>
        </div>
        <div class="img-box" style="margin-top:40px">
          <img src="../../assets/images/news/news7/news1.png" alt="">
        </div>
        <div class="news-content" style="margin-top:20px">
          <p style="text-align:justify;font-weight:600;">
            01智慧课堂：黑板课桌全部智慧化
          </p>
          <p style="text-align:justify;">
            9月17日上午8时30分，老师准时给五年级孩子们上《运算定律》课。借助智慧黑板和智慧课桌，不用厚厚的教案和教具，40分钟从头至尾学生抢答积极，气氛十分活跃。
          </p>
          <p style="text-align:justify;">
            “这道题谁来回答？”课堂上，付诗婷语音刚落，数十名学生轻拍智慧课桌，智慧黑板大屏幕上会第一时间显示最先抢答的学生姓名。
          </p>
          <p style="text-align:justify;">
            做题环节，老师轻点黑板，题目就会快速出现在每个孩子的课桌桌面上。做完题后孩子们轻点桌面，黑板上会立马显示有多少人提交正确率为多少。要想看到某位学生的答案，老师也只需在黑板上点击该学生姓名。当然，通过投影仪展示学生的纸质作业更是“小菜一碟”，看不清楚时还能用手指拉大。
          </p>
          <p style="text-align:justify;">
            记者了解到，智慧黑板内置高性能电脑，可以随时调用各种学科所需的学习资料，有课本相关知识，有随堂测验题目，还可以根据不同学生自动生成不同的考试题目。所有这些内容都可以通过无线网络实时传输到学生的课桌电脑上，学生可以马上做答，电脑自动评改试卷，将学生的学习情况分别记录下来，形成学生个性化的学习效果分析，并将学生易错题自动保存下来，以供以后学生随时复习与巩固提高。
          </p>
          <p style="text-align:justify;">
            智慧课桌是孩子们的学习终端，比传统课桌多了一个屏幕，可以与PC、云平台连接互动，访问资源，播放多媒体及课件，实现远程操作，方便教学与互动。家长也不用担心视力问题，屏幕采用哑光防眩技术，保护视力。而且，这些课桌的控制终端掌握在老师手中。
          </p>
        </div>
        <div class="img-box" style="margin-top:20px">
          <img src="../../assets/images/news/news7/news2.png" alt="">
        </div>
        <div class="news-content" style="margin-top:20px">
          <p style="text-align:justify;font-weight:600;">
            02智慧校园：温度灯光一键控制
          </p>
          <p style="text-align:justify;">
            “智慧课桌改变了我的教学方式，以前我需要事无巨细将所有内容放到PPT里，上课时一步步通过触碰展示给孩子们，学生更多的是一种听和接受的模式，在智慧课堂里，孩子们能够很大程度参与其中，提高了学习的自主性、积极性和兴趣，沉闷的数学课堂增加了很多活力。”付诗婷说。
          </p>
          <p style="text-align:justify;">
            “感觉课堂更好玩了些，方便很多”“我坐在后面，眼睛不太好，大屏幕上的东西传送到智慧课桌上，看起来就会轻松一些”……学生们告诉记者。
          </p>
          <p style="text-align:justify;">
            记者了解到，这套智慧教室系统为本土企业新云网研发，以透明计算技术为核心，同时用到了物联网、大数据、人工智能等先进技术。除了长沙市实验小学，目前在湖南师大附中博才实验中学梅溪湖校区、周南学士实验学校等多所学校也有应用。
          </p>
          <p style="text-align:justify;">
            除了智慧课堂，炫酷的“物联网”技术也走进了校园。在长沙市实验小学门口，智能一卡通系统，实时记录学生的进出学校时间，如果需要，可以将学生进出校门的照片实时发送到学生家长手机上。走入校园，先进的数字IP广播让校园不寂寞，它会在合适的时间、合适的地方，响起某些特定的音乐，提醒着孩子们日常作息。
          </p>
          <p style="text-align:justify;">
            物联网设备则能实时监测学校环境，感知光线的强弱、温度与湿度的细微变化，并根据变化自动控制教室灯光与空调设备，调节环境的光线、温度和湿度；而且它还拥有“远程遥控”功能，哪怕你是在地球的另一端，只要有通信网络，也能实时控制学校的各处设备。
          </p>
        </div>
        <div class="img-box" style="margin-top:20px">
          <img src="../../assets/images/news/news7/news3.png" alt="">
        </div>
        <div class="news-content" style="margin:20px 0 40px">
          <p style="text-align:justify;font-weight:600;">
            03未来学校是怎样的？
          </p>
          <p style="text-align:justify;">
            教育和信息化相结合，就像魔法师一样，正在给传统教育带来巨大的变化。未来学校是个什么样子？在长沙市实验小学校长王云霞看来，教育信息化2.0行动计划给了方向：构建“互联网+”条件下的人才培养新模式，发展基于互联网的教育服务新模式，探索信息时代教育治理新模式。
          </p>
          <p style="text-align:justify;">
            “未来学校首先是让孩子们的学习方式发生改变。”王云霞说，作为小学，最关键的是要打开孩子的眼界，也要让家长明白，学校现在培养的是未来的人，不能再用传统的方式。为此，学校在硬件方面引进了最先进的设施，让课堂发生改变，把原来被动的学习转变成主动学习和交互式学习，在智慧课堂里，孩子们更加注重合作和自我探索，学习的动力更加充足。
          </p>
          <p style="text-align:justify;">
            王云霞告诉记者，她非常赞同朱永新在《未来学校》一书中的观点，未来学校将成为一个学习中心，要让学生的学习发生改变，老师也要不断学习发生改变。因为设备再先进，关键是要老师和学生把它用好。“传统的教学方式可能通过晚上回去复习或者批改作业才能发现学生的不足，通过信息化手段这个过程就大大缩短，从而可以及时进行点对点辅导。”
          </p>
          <p style="text-align:justify;">
            未来社会需要什么样的人？创新能力和自信缺一不可。智慧课堂就能通过AI等技术来发现学生的有点和不足。“我们以后每一年会生成一个综合素质评价，相当于一份成长档案。通过它，为孩子的未来提供预判和指引，比如有的运动方面有天赋，有的艺术方面有天赋。”王云霞告诉记者，随着5G时代到来，学习随时随地发生，今后来学校将不再是知识的学习，更多的是情感的交流，可能更注重情商和品格的培养。
          </p>
        </div>

        <div class="pagination">
          <div class="a-more">
            <span @click="$router.push('/indexOs/newsDetails6')">上一条：我是接班人”网络大课堂反响强烈！近700万人次观看</span>
            <span @click="$router.push('/indexOs/newsDetails8')">下一条：【壮丽70年·奋斗新时代】星城闪耀“未来之光</span>
          </div>
          <el-button plain @click="backGo()">
            返回
          </el-button>
        </div>
      </div>
      <!-- 搜索区域 -->
      <!-- <div class="search-right">
        <el-input
          v-model="keyWord"
          placeholder="查询你喜欢的内容"
          suffix-icon="el-icon-search"
        />
        <div class="hot-news">
          <div class="hot-title">
            <span>最热文章</span>
            <i class="hot-icon" />
          </div>
          <div class="lb-news">
            <router-link class="dg-news" to="/indexOs/newsDetails1">
              <img src="../../assets/images/news/news2-1.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  中共中央政治局常委、国务院副总理 韩正来我公司视察
                </h3>
                <h5 class="time">
                 2020-06-01
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails2">
              <img src="../../assets/images/news/news2-2.png">
              <div class="news-introduce">
                <h3 class="news-title">
                    岳麓山大学科技城：强化科技人才支撑 打造湖南创新...
                </h3>
                <h5 class="time">
                  2020-06-03
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails3">
              <img src="../../assets/images/news/news2-3.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  大科城软件产业联盟拍了拍
                </h3>
                <h5 class="time">
                  2020-08-03
                </h5>
              </div>
            </router-link>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewNews',
  data() {
    return {
      keyWord: ''
    };
  },
  methods: {
    backGo() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1400px) {
.preview-news{
  width: 100%;
  height: auto;
  background: #F5F5F5;
  .main{
    width: 800px;
    height: auto;
    margin: 0 auto;
    display: flex;
    padding-top: 58px;
    justify-content: space-between;
    .left{
      flex: 1;
      margin-right: 63px;
      h3{
        color: #313131;
        font-size:24px;
        font-weight: bolder;
        text-align: center;
      }
      .news-setting{
        color: #B5B5B5;
        display: flex;
         font-size:18px;
        .time{
          font-size:18px;
          color: #B5B5B5;
          font-weight: 100;
          width: 100%;
          text-align: center;
        }
        .num{
          font-size:18px;
          margin-left: 30px;
          font-weight: 100;
        }
      }
      .news-content{
        p{
          font-size: 18px;
          color: #313131;
          line-height: 32px;
          text-indent: 36px;
        }
        .img-explain{
          text-align:justify;
          text-align: center;
          font-size:16px;
        }
      }
      .img-box{
        width: 100%;
    display: flex;
    justify-content: center;
        img{
          width:800px;
        }
      }
    }
    .pagination{
      display: flex;
      justify-content: space-between;
      padding: 50px 0 150px;
      border-top: 1px solid #DDDDDD;
      .a-more{
        display: flex;
        flex-direction: column;
        font-size:18px;
        line-height:32px;
        color:rgba(49,49,49,1);
        span{
          display: inline-block;
          width:600px;
          white-space: nowrap;
          text-overflow:ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
      }
      .el-button{
        width: 134px;
        height: 48px;
        border: 1px solid rgba(253,156,2,1);
        opacity: 1;
        border-radius: 10px;
        font-size:24px;
        color:rgba(253,156,2,1);
      }
    }
    // 搜索区域
    .search-right{
      width: 334px;
      .el-input{
        width: 329px;
        height: 45px;
      }
      .el-input__inner{
        border-radius: 5px;
      }
      .el-input__icon{
        font-size: 24px;
      }
      .hot-news{
        .hot-title{
          font-size:24px;
          margin-bottom: 12px;
          color:#313131;
          .hot-icon{
            display: inline-block;
            background: url('../../assets/images/news/hot.png');
            width: 18px;
            height: 22px;
            margin-left: 6px;
          }
        }
        .dg-news{
          display: flex;
          margin-bottom: 22px;
          img{
            width:132px;
            height:76px;
          }
          .news-introduce{
            flex: 1;
            margin-left: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .news-title{
              font-size:16px;
              font-weight: 600;
              color: #313131;
              box-sizing: border-box;
              text-overflow:ellipsis;
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
              overflow: hidden;
            }
            .time{
              font-size:18px;
              color: #B5B5B5;
              font-weight: 100;
            }
          }
          &:hover{
            .news-title{
              color:rgba(253,156,2,1);
            }
          }
        }
      }
    }
  }
}
}
@media screen and (max-width: 1400px) {
  .preview-news{
    width: 100%;
    height: auto;
    background: #fff;
    .main{
      width: 100%;
      height: auto;
      display: flex;
      padding-top: 1rem;
      justify-content: space-between;
      padding:1.07rem .48rem;
      .left{
        flex: 1;
        h3{
          color: #313131;
          font-size:.48rem;
          font-weight: bolder;
          text-align: center;
        }
        .news-setting{
          color: #B5B5B5;
          display: flex;
          font-size:18px;
          .time{
            font-size:.267rem;
            color: #B5B5B5;
            font-weight: 100;
            width: 100%;
            text-align: center;
          }
          .num{
            font-size:18px;
            margin-left: 30px;
            font-weight: 100;
          }
        }
        .news-content{
          width: 100%;
          p{
            font-size: .373rem;
            color: #313131;
            line-height: .7rem;
            text-indent: .747rem;
          }
          .img-explain{
            text-align:justify;
            text-align: center;
            font-size:.267rem;
          }
        }
        .img-box{
          width: 100%;
          display: flex;
          justify-content: center;
          img{
            width:100%;
            height: 100%;
          }
        }
      }
      .pagination{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: .53rem;
        .a-more{
          display: flex;
          flex-direction: column;
          font-size:.32rem;
          span{
            display: inline-block;
            width:8.933rem;
            cursor: pointer;
            color:rgba(49,49,49,1);
            height: auto;
            padding:.267rem .32rem;
            border: 1px solid #8A8684;
            border-radius: .267rem;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;
            margin-bottom: .48rem;
          }
        }
        .el-button{
          width: 100%;
          font-size:.427rem;
          border: 1px solid rgba(253,156,2,1);
          padding:.267rem .32rem;
          border-radius: .267rem;
          color:rgba(253,156,2,1);
        }
      }
      // 搜索区域
      // .search-right{
      //   width: 334px;
      //   .el-input{
      //     width: 329px;
      //     height: 45px;
      //   }
      //   .el-input__inner{
      //     border-radius: 5px;
      //   }
      //   .el-input__icon{
      //     font-size: 24px;
      //   }
      //   .hot-news{
      //     .hot-title{
      //       font-size:24px;
      //       margin-bottom: 12px;
      //       color:#313131;
      //       .hot-icon{
      //         display: inline-block;
      //         background: url('../../assets/images/news/hot.png');
      //         width: 18px;
      //         height: 22px;
      //         margin-left: 6px;
      //       }
      //     }
      //     .dg-news{
      //       display: flex;
      //       margin-bottom: 22px;
      //       img{
      //         width:132px;
      //         height:76px;
      //       }
      //       .news-introduce{
      //         flex: 1;
      //         margin-left: 8px;
      //         display: flex;
      //         flex-direction: column;
      //         justify-content: space-between;

      //         .news-title{
      //           font-size:16px;
      //           font-weight: 600;
      //           color: #313131;
      //           box-sizing: border-box;
      //           text-overflow:ellipsis;
      //           display:-webkit-box;
      //           -webkit-box-orient:vertical;
      //           -webkit-line-clamp:2;
      //           overflow: hidden;
      //         }
      //         .time{
      //           font-size:18px;
      //           color: #B5B5B5;
      //           font-weight: 100;
      //         }
      //       }
      //       &:hover{
      //         .news-title{
      //           color:rgba(253,156,2,1);
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}
</style>
